<template>
  <div class="h5-login" v-wechat-title="'登录'">
    <span class="h5-login-logo">
      <img src="@/assets/img/logoinimg.png" alt="logoin"/>
    </span>
    <p class="h5-login-title">
      手机号登录
      <span @click="$router.push(`/register?type=${type}`)">注册</span>
    </p>
    <span class="h5-login-subtitle">未注册手机号验证通过后将自动注册</span>
    <div>
      <van-form @submit="onSubmit">
        <van-field
          class="h5-login-inp"
          v-model="form.userPhone"
          name="请输入手机号"
          placeholder="请输入手机号"
          :rules="[{ required: true}]"
        />
        <van-field
          class="h5-login-inp"
          v-model="form.userCode"
          type="text"
          name="请输入验证码"
          placeholder="请输入验证码"
          :maxlength="6"
          :rules="[{ required: true}]"
        >
          <template #button>
            <span @click="getMyCode">{{ codeText }}</span>
          </template>
        </van-field>
        <div class="h5-login-protocol">
          <van-checkbox v-model="checked" icon-size="16px" checked-color="#EA0B06"/>
          <p>我已阅读同意<span @click="$router.push('/protocol/serve')">《鸿联云平台服务协议》、</span><span
            @click="$router.push('/protocol')">《鸿联云法律声明及隐私权政策》</span></p>
        </div>
        <div style="margin-top: 16px;">
          <van-button block class="btn-red" native-type="submit">立即登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { bindOpenId, registerSource } from '@/api/common'
import { throttle } from '@/utils'

import { sendMobile } from '@/api/oauth/cas'

export default {
  computed: {
    type () {
      return this.$route.query.type
    }
  },
  data () {
    return {
      form: {
        userPhone: '',
        userCode: ''
      },
      checked: false,
      disabled: false,
      codeText: '获取验证码'
    }
  },
  methods: {
    ...mapActions(['Login', 'GetInfo']),
    async getMyCodeson () {
      if (this.disabled) {
        return
      }
      if (!this.form.userPhone) {
        this.$notify({
          type: 'warning',
          message: '手机号不得为空'
        })
      } else {
        await sendMobile(this.form.userPhone, 'login')
        let maxNum = 60
        const oldCodeText = this.codeText
        this.codeText = `${maxNum}s重新发送`
        this.disabled = true
        const codeCountDown = setInterval(() => {
          const countDownNum = maxNum--
          this.codeText = `${countDownNum}s重新发送`
          if (countDownNum == 0) {
            this.codeText = oldCodeText
            clearInterval(codeCountDown)
            this.disabled = false
          }
        }, 1000)
      }
    },

    async onSubmit () {
      let path = '/'
      if (!this.checked) {
        this.$notify({
          type: 'warning',
          message: '请阅读并同意平台协议'
        })
        return
      }
      const {
        userPhone: username,
        userCode: password
      } = this.form
      const res = await this.Login({
        username,
        password,
        loginType: '3'
      })
      const userinfo = await this.GetInfo()
      if (res.res_code === '1001') {
        path = '/register/success-login'
      }
      if (this.$route.query.type === 'activity') {
        console.log(userinfo)
        const data = {
          userId: userinfo.id,
          registerSource: '生态伙伴招募'
        }
        registerSource(data).then((res) => {
        })
        path = '/register/prompt'
      }

      if (localStorage.getItem('openId') && !userinfo.openId) {
        const openId = localStorage.getItem('openId')
        bindOpenId({ openId }).then(() => {
          console.log('用户openId绑定成功')
        }).catch(() => {
          console.log('用户openId绑定失败')
        })
      }
      this.$router.push(path)
    },

    getMyCode: throttle(function (...args) {
      this.getMyCodeson(...args)
    }, 5000)

  }
}
</script>

<style lang="scss" scoped>
.h5-login {
  padding: 48px 16px 0;

  .h5-login-logo {
    display: block;
    margin-bottom: 48px;

    > img {
      display: block;
      width: 52px;
      margin: 0 auto;
    }
  }

  .h5-login-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    color: #002626;
    margin-bottom: 12px;

    > span {
      font-size: 16px;
      line-height: 22px;
      color: #002626;
    }
  }

  .h5-login-subtitle {
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    display: block;
  }

  .h5-login-inp {
    padding: 8px 14px;
    border: 1px solid #eee;
    margin-top: 16px;

    &::after {
      display: none;
    }
  }

  .h5-login-protocol {
    display: flex;
    align-items: baseline;
    margin-top: 48px;

    > p {
      flex: 1;
      font-size: 12px;
      margin-left: 7px;

      > span {
        color: #5386CB;
      }
    }
  }
}
</style>
